// Tabs & Select
var tabsTriggers = document.querySelectorAll('.js-product-info-tab');
var tabSelected = document.querySelectorAll('.js-product-info-tab-selected-trigger');
if (!!tabsTriggers.length) {
	tabsTriggers.forEach(function (tab) {
		tab.addEventListener('click', function () {
			// Handle Active tab and Panel
			var parent = tab.closest('.hoox-product-info__content');
			var activeTab = parent.querySelector('.js-product-info-tab.active');
			var activePanel = parent.querySelector('.hoox-product-info__panel.active');
			var newPanel = parent.querySelector(`.hoox-product-info__panel[data-id="${tab.dataset.id}"]`);
			if (activeTab) {
				activeTab.classList.remove('active');
			}
			if (activePanel) {
				activePanel.classList.remove('active');
			}
			if (newPanel) {
				newPanel.classList.add('active');
			}
			if (tab) {
				tab.classList.add('active');
			}

			// Handle Select on mobile
			var elChosenTab = parent.querySelector('.hoox-product-info__tab--selected span');
			var elTabs = parent.querySelector('.hoox-product-info__tabs');
			if (elChosenTab) {
				elChosenTab.innerHTML = tab.innerHTML;
			}
			if (elTabs) {
				elTabs.classList.remove('active');
			}
		})
	})
}
if (!!tabSelected.length) {
	tabSelected.forEach(function (tab) {
		tab.addEventListener('touchstart', function () {
			var parent = tab.closest('.hoox-product-info__content');
			var elTabs = parent.querySelector('.hoox-product-info__tabs');
			if (elTabs) {
				elTabs.classList.toggle('active');
			}
		})
	})
}


// Render bullet icon for each list item
var hooxProductInfoBulletListItem = document.querySelectorAll('.hoox-product-info__panel-info ul li');
var hooxProductInfoBulletIcon = document.querySelector('.hoox-product-info__bullet-icon');
if (
	!!hooxProductInfoBulletListItem.length &&
	hooxProductInfoBulletIcon
) {
	[...hooxProductInfoBulletListItem].forEach(function (li) {
		var clonedImg = hooxProductInfoBulletIcon.cloneNode(true);
		clonedImg.classList.remove('hidden');
		li.insertBefore(clonedImg, li.firstChild);
	})
}
